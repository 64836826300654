import './App.css';

function App() {
  return (
    <div className="App">
      <div className='welcome-text'>
        <p>Cibo will be back soon</p>
      </div>
    </div>
  );
}

export default App;
